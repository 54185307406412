export const RoomCardData = [
    {
      imgsrc: "../../assets/images/royalty-main.jpeg",
      title: "Suite Royalty",
      text: "Luxurious king-size bed with lots of space, oversized claw foot bathtub, wet bar, mini fridge, coffee maker. Book now for a royal treatment!",
      price: "$189/night",
      view: ""
    },
    {
      imgsrc: "../../assets/images/tranquility-main.jpeg",
      title: "Suite Tranquility",
      text: "Queen-sized bed with treetop views, claw-footed bathtub, bath salts, en-suite sitting area. Come relax and restore balance in your life. Reserve now!",
      price: "$169/night",
      view: ""
    },
    {
      imgsrc: "../../assets/images/divine-main.jpeg",
      title: "Suite Divine",
      text: "Luxurious white linens, queen bed with a fluffy comforter, shower with pulsating showerhead. Click now to reserve your divine experience!",
      price: "$149/night",
      view: ""
    },
    {
      imgsrc: "../../assets/images/bygone-main.jpeg",
      title: "Suite Bygones",
      text: "Step back in time with a queen-sized brass bed, cottage garden feel, claw foot bathtub. Your reservation is waiting, book now for a cozy stay!",
      price: "$129/night",
      view: ""
    }
  ];
  
  
// export const RoomCardData = [

//     {
//         imgsrc : "../../assets/suiteroyalty1.jpg",
//         title : "Suite Royalty",
//         text : "This room has a luxurious king-size bed with comfortable pillows and lots of space. At the end of the day, wash all the stress away in the 168 square-foot bathroom, while taking a hot lavender bath in the oversized claw foot bathtub. The room comes with a wet bar, a mini fridge, and a coffee maker. Enjoy some quiet time in the en-suite. Royalty treatment awaits you, book now!",
//         price: "$189/night",
//         view : ""
//     },

//     {
//         imgsrc : "../../assets/tranquility-1.jpg",
//         title : "Suite Tranquility",
//         text : "Enjoy this room with treetop views while sleeping on the queen-sized bed. You can watch and listen to the trees swaying back and forth-it is very calming. This room comes with a claw-footed bathtub and bath salts to enhance a relaxing soak in the bathtub.  It has an en-suite sitting area to relax and reconnect with good conversation. Come visit with a reservation now to get away from the hustle and bustle of the world, and restore balance in your life.",
//         price: "$169/night",
//         view : ""
//     },

//     {
//         imgsrc : "../../assets/suite-divine.jpg",
//         title : "Suite Divine",
//         text : "This room comes with luxurious white linens and beautiful furniture. You will feel pampered in this queen bed sleeping under this fluffy comforter. Comes with a shower and a pulsating showerhead to relax those tired muscles. Click now to reserve this room; it is calling your name.",
//         price: "$149/night",
//         view : ""
//     },

//     {
//         imgsrc : "../../assets/suite-bygones-1.jpg",
//         title : "Suite Bygones",
//         text : "Step back in time with a queen-sized brass bed and natural wood furniture. Enjoy the relaxing ambiance with a cottage garden feel and lots of light. Take a seat in the chair, and get lost in a good book. This is a smaller room, but with the comfort of home. Indulge in a hot bath in a claw foot bathtub. Your reservation is waiting for you, do not wait, book now.",
//         price: "$129/night",
//         view : ""
//     }

// ];


