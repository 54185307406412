import { React } from "react";
import './TodoStyles.css'
import arts from '../../assets/images/arts.jpg'
import one from '../../assets/images/the-one.jpg'
import calendar from '../../assets/images/calendar.jpg'
import buzz from '../../assets/images/buzz.jpg'

export const Todo = () => {
    return (
		<div className="blog-area">
			<div className="container">
				<div className="row">
					<div className="section-title">
						<div className="main-title">
							<h2>Things To Do</h2>
						</div>
						<div className="section-discription">
							<p>Within walking distance to the Downtown Square where you will find breweries, shopping, restaurants, baseball complex, and entertainment including the Enid Symphony and Gaslight Theater.</p>
						</div>
					</div>
				</div>
				<div className="row mar-top">
					<div className="blog_list">
						<div className="col-lg-8 offset-md-2 col-md-6">
							<div className="single-blog">
								<div className="blog-thumb">
									<img src={one} alt=""/>
								</div>
								<div className="blog-content">
									<div className="blog-title">
									</div>
									<div className="blog-titles">
										<h2><a href="blog-details.html">The One</a></h2>
									</div>
									<div className="blog-discription">
										<p>The Joy and Magic of the Christmas experience featuring one of the world's tallest Christmas trees, the “Christ Tree,” in Enid, Oklahoma.</p>
									</div>
								</div>
								<div className="blog-button">
									<a href="https://www.theoneenid.com/">Read More <i className="bi bi-arrow-right"></i></a>
								</div>
							</div>
						</div>
						<div className="col-lg-8 offset-md-2 col-md-6">
							<div className="single-blog">
								<div className="blog-thumb">
									<img src={calendar} alt=""/>
								</div>
								<div className="blog-content">
									<div className="blog-title">
									</div>
									<div className="blog-titles">
										<h2><a href="blog-details.html">Calendar of Events</a></h2>
									</div>
									<div className="blog-discription">
										<p>Special museum exhibits or concerts, festivals or car shows, outdoor vendor shows or performing arts, baseball or auto racing, plan your visit around one or more of Enid’s special events. </p>
									</div>
								</div>
								<div className="blog-button">
									<a href="https://visitenid.org/events/">Read More <i className="bi bi-arrow-right"></i></a>
								</div>
							</div>
						</div>
						<div className="col-lg-8 offset-md-2 col-md-6">
							<div className="single-blog">
								<div className="blog-thumb">
									<img src={buzz} alt=""/>
								</div>
								<div className="blog-content">
									<div className="blog-title">
									</div>
									<div className="blog-titles">
										<h2><a href="blog-details.html">Buzz Eats</a></h2>
									</div>
									<div className="blog-discription">
										<p>Welcome to the restaurant guide to Enid, Oklahoma. Enid has a rich past full of fantastic national and local eating establishments.</p>
									</div>
								</div>
								<div className="blog-button">
									<a href="https://www.enidbuzz.com/listing-category/restaurants/">Read More <i className="bi bi-arrow-right"></i></a>
								</div>
							</div>
						</div>
						<div className="col-lg-8 offset-md-2 col-md-6">
							<div className="single-blog">
								<div className="blog-thumb">
									<img src={arts} alt=""/>
								</div>
								<div className="blog-content">
									<div className="blog-title">
									</div>
									<div className="blog-titles">
										<h2><a href="blog-details.html">Arts and Entertainment</a></h2>
									</div>
									<div className="blog-discription">
										<p>Diversity in our Arts offerings has people around the state and the region recognizing Enid as one of Oklahoma’s fastest-growing arts cities.</p>
									</div>
								</div>
								<div className="blog-button">
									<a href="https://visitenid.org/things-to-do/">Read More <i className="bi bi-arrow-right"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
}