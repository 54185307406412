import React, {useState} from "react";
import "./InquireFormStyles.css"

export const InquireForm = () => {
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [guests, setGuests] = useState('');
    const [room, setRoom] = useState('');

    const sendEmail = () => {
        // const href = 'mailto:southardhousebandb@gmail.com?subject=Reservation&body=' + 
        //     encodeURIComponent(`checkin:${checkIn};checkout:${checkOut};guests:${guests};room:${room}`); 
        const href = `https://secure.thinkreservations.com/southard-housebandb/reservations/availability?start_date=${checkIn}&end_date=${checkOut}&number_of_adults=${guests}&number_of_children=0`
        window.location.href = href;
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        if (name === 'checkin') { setCheckIn(value); }
        if (name === 'checkout') { setCheckOut(value); }
        if (name === 'guests') { setGuests(value); }
        if (name === 'room') { setRoom(value); }
    }
        
    return (
        <div className="contact-section">
        <div className="container">
            <div className="row back">
                <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2 offset-lg-2 col-md-6">
                                <div className="contacts-titles">
                                    <h4>Check In</h4>
                                </div>
                                <div className="form_box">
                                    <input type="date" id="myDate" name="checkin" placeholder="MM/DD/YYYY" onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="contacts-titles">
                                    <h4>Check Out</h4>
                                </div>
                                <div className="form_box">
                                    <input type="date" id="myDate" name="checkout" placeholder="MM/DD/YYYY" onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className="contacts-titles">
                                    <h4>Guests</h4>
                                </div>
                                <div className="form_box">
                                    <select id="guests" name="guests" onChange={handleChange}>
                                      <option value=""></option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <div className="form-button">
                                    <button onClick={sendEmail}>Check Now</button>
                                </div>
                            </div>
                        </div>
                    <div id="status"></div>
                </div>
            </div>
        </div>
    </div>
    );
}
