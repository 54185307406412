import "./RoomCardStyles.css";
import { RoomCardData } from "./RoomCardData";
import { RoomCard } from "./RoomCard";

import React from "react";

export const Room = () => {
  return (
    <div className="flip-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <div className="sub-title">
                <h5>EXPERIENCES</h5>
              </div>
              <div className="main-title">
                <h2>Rooms & Suites</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="work-container">
            <div className="project-heading">
              <div className="project-container">
                {RoomCardData.map((val, ind) => {
                    return(
                      <RoomCard 
                      key={ind}
                      imgsrc = {val.imgsrc}
                      title = {val.title}
                      text = {val.text}
                      view  = {val.view}
                      price = {val.price}
                      />
                      )
                    }
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
