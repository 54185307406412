import "./RoomCardStyles.css";

import { NavLink } from "react-router-dom";

export const RoomCard = (props) => {
  var divStyle = {
    backgroundImage: 'url(' + props.imgsrc + ')',
  };
  return (
    <div className="flip-box">
      <div className="flip-box-inner">
        <div className="flip-box-front" style={divStyle}>
          <div className="flip-single-box">
            <div className="box-title">
              <h3>{props.title}</h3>
              <div className="front-price">
                <p>{props.price}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flip-box-back">
          <div className="flip-title">
            <h2>{props.title}</h2>
          </div>
          <div className="des">
            <p>{props.text}</p>
            <NavLink to="/contact" activeClassName="active">Book Now</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
