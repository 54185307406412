import "./HeaderWelcomeStyles.css";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const titleDiv = (
  <div className="overlay-text">
   <div className="slider-content">
      <div className="slider-title">
        <h5>Bed and Breakfast</h5>
        <h1>Southard House</h1>
      </div>
      <div className="slider-discription">
        <p>The Southard House, where history meets comfort.</p>
      </div>
    </div>
  </div>
)

export const HeaderWelcome = () => {
  return (
  <Carousel    
    showArrows={false}
    showStatus={false}
    showIndicators={false}
    showThumbs={false}
    autoPlay={true}
    infiniteLoop={true}
    interval={5000}
    animationHandler='fade'
    swipeable={false} >
    <div className="image-container">
      <img src="assets/images/headerSlider1.jpg" />
      {titleDiv}       
    </div>
    <div className="image-container">
      <img src="assets/images/headerSlider2.jpg" />
      {titleDiv}       
    </div>
  </Carousel>
  );
};

