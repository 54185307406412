import React from "react";
import { Navbar }   from "../components/navbar/Navbar";
import { Footer }   from "../components/footer/Footer";
import { HeaderSmall }   from "../components/header/HeaderSmall";
import { AboutContent } from "../components/about/AboutContent";

export const About = () => {
  return (
    <div>
      <Navbar />
      <HeaderSmall heading="ABOUT" text="" />
      <AboutContent/>
      <Footer />
    </div>
  );
};
