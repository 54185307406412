import "./FooterStyles.css";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaHome,
  FaPhone,
  FaMailBulk,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
} from "react-icons/fa";
export const Footer = () => {
  return (
    <div className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="widgets-company-information">
              <div className="footer-logo">
                Southard House
              </div>
              <div className="footer-discription">
                <p>The Southard House, where history meets comfort.</p>
              </div>
              <ul>
                <li><a href="http://facebook.com/Southardhouse"><i className="bi bi-facebook"></i></a></li>
                <li><a href="https://www.instagram.com/sohobandb/?igshid=YmMyMTA2M2Y%3D"><i className="bi bi-instagram"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 col-md-6">
            <div className="row">
              <div className="col-lg-4">
                <div className="single-footer">
                </div>
              </div>
              <div className="col-lg-8">
                <div className="single-footer">
                  <div className="footer-content">
                    <div className="footer-title">
                      <h3>Contact Us</h3>
                    </div>
                  </div>
                  <div className="footer-address">
                    <p>518 W Pine Avenue Enid, OK 73701</p>
                    <p><a href='mailto:southardhousebandb@gmail.com'>southardhousebandb@gmail.com</a></p>
                    <p><a href="tel:4058124848">405-812-4848</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="copyright-title">
                <h3>Copyright ©  all rights reserved.</h3>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="copyright-menu">
                <ul>
                  <li><a href="#">Home</a></li>
                  <li><NavLink to="/contact">Contact</NavLink></li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
