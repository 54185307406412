import React from "react";
import { Navbar } from "../components/navbar/Navbar";
import { Footer } from "../components/footer/Footer";
import { HeaderSmall } from "../components/header/HeaderSmall";
import { Room } from "../components/room/Room";

export const Rooms = () => {
  return (
    <div>
      <Navbar />
      <HeaderSmall heading="ROOM EXPERIENCES" text="The Southard House, where history meets classic comfort." />
      <Room />
      <Footer />
    </div>
  );
};

