import React, { useEffect } from "react";
import { Welcome } from "./routes/Welcome";
import { About } from "./routes/About";
import { Todos } from "./routes/Todos";
import { Contact } from "./routes/Contact";
import { Rooms } from "./routes/Rooms";
import { Route, Routes, useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="rooms" element={<Rooms />} />
        <Route path="about" element={<About />} />
        <Route path="todos" element={<Todos />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
