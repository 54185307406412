import React from "react";
import { Navbar } from "../components/navbar/Navbar";
import { Footer } from "../components/footer/Footer";
import { HeaderSmall } from "../components/header/HeaderSmall";
import { ContactForm } from "../components/contact/ContactForm";

export const Contact = () => {
  return (
    <div>
      <Navbar />
      <HeaderSmall heading="For More Information" text="Please contact us through our email form or call us directly and we would be happy to answer any questions you may have regarding your upcoming stay with us." />
      <ContactForm />
      <Footer />
    </div>
  );
};

