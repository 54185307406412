import React from "react";
import { Todo } from "../components/todo/Todo";
import { Navbar } from "../components/navbar/Navbar";
import { Footer } from "../components/footer/Footer";
import { HeaderSmall } from "../components/header/HeaderSmall";

export const Todos = () => {
  return (
    <div>
      <Navbar />
      <HeaderSmall heading="ROOM EXPERIENCES" text="The Southard House, where history meets classic comfort." />
      <Todo/>
      <Footer/>
    </div>
  );
};
