import "./AboutContentStyles.css";
import { React, useState, useRef } from "react";
import { Player } from "video-react";
import 'video-react/dist/video-react.css';

export const AboutContent = () => {
    const [showOverlay, setShowOverlay] = useState(true);
    const playerRef = useRef(null);

    const handleOverlayClick = () => {
        setShowOverlay(false); // Hide the overlay when clicked
        if (playerRef.current) {
            playerRef.current.play();
          }
      };

    return(
        <div className="about-area upper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <div className="sub-title">
                                <h5>ABOUT US</h5>
                            </div>
                            <div className="main-title">
                                <h2>Southard House History</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="about-video">
                                        <div className="about-video-tmb">
                                            <Player ref={playerRef} autoPlay={false} >
                                                <source src="../../assets/videos/george-southard-histroy-video_1rv.mp4" />
                                            </Player>  
                                        </div>
                                        {showOverlay && (
                                            <div class="video-icon" onClick={handleOverlayClick}>
                                                <i class="bi bi-play"></i>
                                            </div>
                                        )}
                                    </div>
                                    <div className="section-discription">
                                        <p>George Southard, an Ohio oil man by trade, had turned his exploration pursuits to gypsum. His Independence Gypsum Company, founded in 1905, in what was to become his namesake town of Southard, OK. The gyspum deposit he discovered supplied and continues to supply one of the highest quality ore in the world. When he sold the business due to the Depression, he moved to Enid and built the Southard House. He started construction in 1910 and finished in 1912. Located in the Historic Kenwood Edition of Enid, Oklahoma.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )



}