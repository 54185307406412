import "./AmenitiesContentStyles.css";
import React from "react";
import local1 from "../../assets/images/icon-4.png"
import local2 from "../../assets/images/icon-1.png"
import local3 from "../../assets/images/icon-5.png"
import local4 from "../../assets/images/icon-6.png"

export const AmenitiesContent = () => {
    return(
        <div className="hotel-faciliti-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="section-title">
					<div className="sub-title">
						<h5>Bed & Breakfast Amenities</h5>
					</div>
					<div className="main-title">
						<h2>Discover Our Charming Retreat</h2>
					</div>
					<div className="section-discription">
						<p>Explore our array of amenities, meticulously curated to ensure your stay is nothing short of exceptional. From cozy accommodations to delightful dining experiences, we invite you to indulge in the comforts of our luxurious retreat.</p>
					</div>
				</div>
                    </div>
                </div>
                <div className="row paddi">
                    <div className="col-lg-3">
                        <div className="single-faciliti">
                            <div className="faciliti-thumb">
                                <img src={local1} alt=""/>
                            </div>
                            <div className="faciliti-title">
                                    <h2>Fast Wifi</h2>
                                    <h2>Roku</h2>
                                    <h2>Library</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="single-faciliti">
                            <div className="faciliti-thumb">
                                <img src={local2} alt=""/>
                            </div>
                            <div className="faciliti-title">
                                <h2>Coffee Maker</h2>
                                <h2>Hair Dryer</h2>
                                <h2>Iron</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="single-faciliti">
                            <div className="faciliti-thumb">
                                <img src={local3} alt=""/>
                            </div>
                            <div className="faciliti-title">
                                <h2>Drinks/Snacks</h2>
                                <h2>Microwave</h2>
                                <h2>Mini-Fridge</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="single-faciliti">
                            <div className="faciliti-thumb">
                                <img src={local4} alt=""/>
                            </div>
                            <div className="faciliti-title">
                                <h2>24-Hr Access</h2>
                                <h2>Guest Laundry</h2>
                                <h2>Free Parking</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}