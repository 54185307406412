import React from "react";
import { Navbar } from "../components/navbar/Navbar";
import { HeaderWelcome } from "../components/header/HeaderWelcome";
import { Footer } from "../components/footer/Footer";
import { AboutContent } from "../components/about/AboutContent"
import { Room } from "../components/room/Room";
import { InquireForm } from "../components/inquire/InquireForm";
import OperatingHours from "../components/header/OperatingHours";
import { AmenitiesContent } from '../components/amenities/AmenitiesContent';

export const Welcome = () => {
  return (
    <div>
        <Navbar />
        <HeaderWelcome />
        <InquireForm/>
        <OperatingHours/>
        <AmenitiesContent />
        <Footer />
    </div>
  );
};
 
