import React from 'react';
import './OperatingHoursStyles.css';
import kitchenImg from '../../assets/images/kitchen.jpeg';

const OperatingHours = () => {
  return (
    <div className="single-service-section">
    <div className="container">
      <div className="row">
      <div className="col-lg-6">
          <div className="row">
              <img src={kitchenImg}/>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="single-service">
            <div className="section-title">
              <div className="main-title">
                <h2>Southard House</h2>
              </div>
            </div>
            <div className="service-discrip">
              <p>Nestled in Enid's charming Kenwood Historic District and just steps away from downtown attractions, Southard House offers a premier lodging experience. Whether it's a romantic escape, a special celebration, or a business trip, our four exquisite guest suites promise a tranquil retreat. Relax in our inviting parlor, explore our boutique for distinctive culinary and artisanal treasures, savor complimentary evening desserts, and awaken to delightful continental breakfasts during the week or gourmet breakfasts on weekends. Come share in our vision and experience the epitome of luxury lodging in northwest Oklahoma. We can't wait to welcome you.</p>
            </div>
          </div>
          <div className="single-service-title">
            <div className="service-titles">
              <h3>Hours</h3>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="service-icon-list">
                  <ul>
                    <li><i className="bi bi-clock"></i> Sunday: 4:00 pm - 11:30 am</li>
                    <li><i className="bi bi-clock"></i> Monday: 4:00 pm - 11:30 am</li>
                    <li><i className="bi bi-clock"></i> Tuesday: 4:00 pm - 11:30 am</li>
                    <li><i className="bi bi-clock"></i> Wednesday: 4:00 pm - 11:30 am</li>
                    <li><i className="bi bi-clock"></i> Thursday: 4:00 pm - 11:30 am</li>
                    <li><i className="bi bi-clock"></i> Friday: 4:00 pm - 11:30 am</li>
                    <li><i className="bi bi-clock"></i> Saturday: 4:00 pm - 11:30 am</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

    {/* // <div className="container hours">
    //   <div className="row justify-content-center">
    //     <div className="col-12 text-center">
    //       <p>518 W. Pine Ave.</p>
    //       <p>Enid, OK 73701</p>
    //     </div>
    //   </div>
    //   <hr />
    //   <div className="row">
    //     <div className="col">
    //       <ul className="list-unstyled">
    //         <li><strong>Monday</strong></li>
    //         <li><strong>Tuesday</strong></li>
    //         <li><strong>Wednesday</strong></li>
    //         <li><strong>Thursday</strong></li>
    //         <li><strong>Friday</strong></li>
    //         <li><strong>Saturday</strong></li>
    //         <li><strong>Sunday</strong></li>
    //       </ul>
    //     </div>
    //     <div className="col">
    //       <ul className="list-unstyled text-right">
    //         <li>CLOSED</li>
    //         <li>CLOSED</li>
    //         <li>CLOSED</li>
    //         <li>CLOSED</li>
    //         <li>3pm - 11:30am</li>
    //         <li>3pm - 11:30am</li>
    //         <li>Check out 11am</li>
    //       </ul>
    //     </div>
    //   </div>
    // </div> */}
export default OperatingHours;
