
import "./ContactFormStyles.css";
import React, {useState} from "react";

export const ContactForm = () => {
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [guests, setGuests] = useState('');
  const [room, setRoom] = useState('');

  const sendEmail = () => {
      // const href = 'mailto:southardhousebandb@gmail.com?subject=Reservation&body=' + 
      //     encodeURIComponent(`checkin:${checkIn};checkout:${checkOut};guests:${guests};room:${room}`); 
      const href = `https://secure.thinkreservations.com/southard-housebandb/reservations/availability?start_date=${checkIn}&end_date=${checkOut}&number_of_adults=${guests}&number_of_children=0`
      window.location.href = href;
  }

  const handleChange = (e) => {
      const {name, value} = e.target;
      if (name === 'checkin') { setCheckIn(value); }
      if (name === 'checkout') { setCheckOut(value); }
      if (name === 'guests') { setGuests(value); }
      if (name === 'room') { setRoom(value); }
  }

  return (
    <div class="call-do-action-area ">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="single-call-do-action">
              <div class="section-title">
                <div class="main-title">
                  <h2>Call us</h2>
                </div>
                <div class="section-discription">
                  <p>To check availability and reserve your relaxing getaway, contact us with your desired dates and prefered room and we will get you scheduled.</p>
                </div>
              </div>
              <div class="call-icon">
                <i class="bi bi-telephone"></i>
              </div>
              <div class="call-number">
                <span><a href="tel:4058124848">405-812-4848</a></span>
                <p>For More Information</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="single-boking-form">
                <div class="section-title">
                <div class="sub-title">
                  <h5>ROOMS &amp; SUITES</h5>
                </div>
                <div class="main-title">
                  <h2>Booking Form</h2>
                </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                    <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="boking-titles">
                        <h4>Check In</h4>
                      </div>
                      <div class="form_box">
                        <input type="date" id="myDate" name="checkin" placeholder="MM/DD/YYYY"  onChange={handleChange}/>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="boking-titles">
                        <h4>Check Out</h4>
                      </div>
                      <div class="form_box">
                        <input type="date" id="myDate" name="checkout" placeholder="MM/DD/YYYY"  onChange={handleChange}/>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="boking-titles">
                        <h4>Guests</h4>
                      </div>
                      <div class="form_box">
                        <select id="guests" name="guests"  onChange={handleChange}>
                          <option value=""></option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-button">
                        <button onClick={sendEmail}>Check Availability</button>
                      </div>
                    </div>
                  </div>
                <div id="status"></div>
              </div>
            </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
