import "./HeaderSmallStyles.css";
import React, { Component } from "react";

export class HeaderSmall extends Component {
  render() {
    return (
      <div class="breadcumb-area d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breacumb-content text-center">
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
